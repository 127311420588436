<template>
    <modal
        id="registrationSuccess"
        :title="$t('modal.registration.sentTitle')"
        :backdrop-close="false"
        type="general"
    >
        <form
            id="re-send"
            @submit.prevent="reSend"
        >
            <p class="sm mb-4">
                {{ $t('modal.registration.sentText', { email: verificationEmail }) }}
            </p>
            <p class="sm mb-4">
                {{ $t('modal.registration.notReceivedText') }}
            </p>
            <div
                class="form-group w100"
                :class="{ error: errors.email }"
            >
                <label>{{ $t('form.fields.label.email_address') }}</label>
                <input
                    v-model="input.email"
                    type="text"
                    class="form-control"
                    name="email"
                    disabled
                >
                <span
                    v-if="errors.email"
                    class="error-message"
                >{{
                    errors.email
                }}</span>
            </div>
            <b-button
                :disabled="sending"
                variant="primary"
                class="fill lg re-send mt-5"
                type="submit"
            >
                {{ $t('modal.registration.reSendButton') }}
            </b-button>
        </form>
        <div class="member d-flex">
            <p class="sm">
                {{ $t('modal.registration.emailValidated') }}
            </p>
            <p
                class="re-send sm-sbold"
                @click="showLoginPopup()"
            >
                {{ $t('modal.login.heading') }}
            </p>
        </div>
    </modal>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';

    import { formValidations } from '@/services/formValidations';

    import Modal from './Modal.vue';
    export default {
        name: 'RegistrationSuccessModal',
        components: {
            Modal,
        },
        data() {
            return {
                input: {
                    email: '',
                },
                errors: {},
                sending: false,
            };
        },
        computed: {
            ...mapState('auth', ['verificationEmail']),
        },
        methods: {
            ...mapMutations('general', ['setModal']),
            ...mapActions('auth', ['reSendVerification']),
            async reSend() {
                this.errors = formValidations(this.input);
                if (Object.keys(this.errors).length !== 0) {
                    return;
                }
                this.sending = true;
                await this.reSendVerification(this.input.email);
                this.sending = false;

                this.closeModal();
                setTimeout(() => {
                    this.setModal({
                        show: true,
                        type: 'reSent',
                    });
                }, 200);
            },
            closeModal() {
                this.setModal({
                    show: false,
                    type: 'registrationSuccess',
                });
            },
            showLoginPopup() {
                this.closeModal();
                setTimeout(() => {
                    this.setModal({
                        show: true,
                        type: 'login',
                    });
                }, 200);
            },
        },
        mounted() {
            this.input.email =
                this.verificationEmail != '' ? this.verificationEmail : '';
        },
        watch: {
            verificationEmail: {
                handler(value) {
                    this.input.email = value;
                },
            },
        },
    };
</script>
